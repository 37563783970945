import feathers from '@feathersjs/client';
import { storageConfig } from './config';
import transportConfig from './transport-config';

export default (
  storage = 'localStorage',
  transport = 'rest',
  server = 'streams'
) => {
  if (transport === 'google') {
    return {};
  }

  const client = feathers();

  client.configure(transportConfig(client, transport, server));
  client.configure(feathers.authentication(storageConfig[storage]));

  return client;
};
